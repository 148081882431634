var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "recommended-products bj" }, [
    _c("div", { staticClass: "title" }, [
      _c("div", { staticClass: "form jn-form-style" }, [
        _c(
          "div",
          { staticClass: "form-item btn-wrap" },
          [
            _c("el-button", {
              staticClass: "refresh",
              staticStyle: { "margin-left": "5px" },
              attrs: { type: "primary", icon: "el-icon-refresh" },
              on: { click: _vm.Refresh },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "form-item" },
          [
            _c("el-input", {
              attrs: { placeholder: "请输入推荐人手机号", clearable: "" },
              model: {
                value: _vm.formData.share_member_mobile,
                callback: function ($$v) {
                  _vm.$set(_vm.formData, "share_member_mobile", $$v)
                },
                expression: "formData.share_member_mobile",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "form-item" },
          [
            _c("el-input", {
              attrs: { placeholder: "请输入推荐人姓名", clearable: "" },
              model: {
                value: _vm.formData.share_member_name,
                callback: function ($$v) {
                  _vm.$set(_vm.formData, "share_member_name", $$v)
                },
                expression: "formData.share_member_name",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "form-item" },
          [
            _c("el-input", {
              attrs: { placeholder: "请输入推荐商品名称", clearable: "" },
              model: {
                value: _vm.formData.product_name,
                callback: function ($$v) {
                  _vm.$set(_vm.formData, "product_name", $$v)
                },
                expression: "formData.product_name",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "form-item" },
          [
            _c("el-input", {
              attrs: { placeholder: "请输入推荐商品货号", clearable: "" },
              model: {
                value: _vm.formData.sku_code,
                callback: function ($$v) {
                  _vm.$set(_vm.formData, "sku_code", $$v)
                },
                expression: "formData.sku_code",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "form-item" },
          [
            _c(
              "el-select",
              {
                attrs: { clearable: "", placeholder: "请选择分享方式" },
                model: {
                  value: _vm.formData.source,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "source", $$v)
                  },
                  expression: "formData.source",
                },
              },
              [
                _c("el-option", { attrs: { label: "分享", value: "1" } }),
                _c("el-option", { attrs: { label: "海报", value: "2" } }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "form-item" },
          [
            _c(
              "el-select",
              {
                attrs: { clearable: "", placeholder: "请选择购买状态" },
                model: {
                  value: _vm.formData.status,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "status", $$v)
                  },
                  expression: "formData.status",
                },
              },
              [
                _c("el-option", { attrs: { label: "未购买", value: "0" } }),
                _c("el-option", { attrs: { label: "已购买", value: "1" } }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "form-item" },
          [
            _c(
              "el-select",
              {
                attrs: {
                  clearable: "",
                  filterable: "",
                  placeholder: "请选择购买城市仓",
                },
                model: {
                  value: _vm.formData.logistics_id,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "logistics_id", $$v)
                  },
                  expression: "formData.logistics_id",
                },
              },
              _vm._l(_vm.storelist, function (item) {
                return _c("el-option", {
                  key: item.id,
                  attrs: { label: item.name, value: item.id },
                })
              }),
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "form-item" },
          [
            _c("el-input", {
              attrs: { placeholder: "请输入购买用户姓名", clearable: "" },
              model: {
                value: _vm.formData.purchase_username,
                callback: function ($$v) {
                  _vm.$set(_vm.formData, "purchase_username", $$v)
                },
                expression: "formData.purchase_username",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "form-item" },
          [
            _c("el-input", {
              attrs: { placeholder: "请输入购买用户电话", clearable: "" },
              model: {
                value: _vm.formData.purchase_mobile,
                callback: function ($$v) {
                  _vm.$set(_vm.formData, "purchase_mobile", $$v)
                },
                expression: "formData.purchase_mobile",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "form-item" },
          [
            _c("el-input", {
              attrs: { placeholder: "请输入订单号", clearable: "" },
              model: {
                value: _vm.formData.order_no,
                callback: function ($$v) {
                  _vm.$set(_vm.formData, "order_no", $$v)
                },
                expression: "formData.order_no",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "form-item" },
          [
            _c("el-date-picker", {
              attrs: {
                "time-arrow-control": true,
                type: "daterange",
                format: "yyyy-MM-dd",
                "value-format": "yyyy-MM-dd",
                "range-separator": "—",
                "start-placeholder": "推荐时间起",
                "end-placeholder": "推荐时间止",
              },
              on: { change: _vm.payChange },
              model: {
                value: _vm.pay_at,
                callback: function ($$v) {
                  _vm.pay_at = $$v
                },
                expression: "pay_at",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "form-item" },
          [
            _c(
              "el-select",
              {
                attrs: {
                  clearable: "",
                  filterable: "",
                  placeholder: "请选择一级品类",
                },
                model: {
                  value: _vm.formData.first_cid,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "first_cid", $$v)
                  },
                  expression: "formData.first_cid",
                },
              },
              _vm._l(_vm.CategoriesList, function (item) {
                return _c("el-option", {
                  key: item.id,
                  attrs: { label: item.name, value: item.id },
                })
              }),
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "form-item" },
          [
            _c(
              "el-select",
              {
                attrs: {
                  clearable: "",
                  filterable: "",
                  placeholder: "请选择二级品类",
                },
                model: {
                  value: _vm.formData.cid,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "cid", $$v)
                  },
                  expression: "formData.cid",
                },
              },
              _vm._l(_vm.subList, function (item) {
                return _c("el-option", {
                  key: item.id,
                  attrs: { label: item.name, value: item.id },
                })
              }),
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "form-item" },
          [
            _c(
              "el-button",
              {
                attrs: { type: "warning", icon: "el-icon-download" },
                on: { click: _vm.onHandleExport },
              },
              [_vm._v("导 出")]
            ),
            _c(
              "el-button",
              {
                attrs: { type: "primary", icon: "el-icon-search" },
                on: { click: _vm.onHandleSearch },
              },
              [_vm._v("查询")]
            ),
          ],
          1
        ),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "auto-table-flex" },
      [
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            attrs: {
              data: _vm.tableData,
              height: "100%",
              border: true,
              "header-cell-style": { color: "#333333", background: "#EFF6FF" },
            },
          },
          [
            _c("el-table-column", {
              attrs: {
                align: "center",
                label: "推荐商品名称",
                prop: "product_name",
              },
            }),
            _c("el-table-column", {
              attrs: {
                align: "center",
                label: "一级品类",
                prop: "first_cid_name",
              },
            }),
            _c("el-table-column", {
              attrs: {
                align: "center",
                label: "二级品类",
                prop: "categories_name",
              },
            }),
            _c("el-table-column", {
              attrs: { align: "center", label: "推荐方式", prop: "source" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(scope.row.source == 2 ? "海报" : "分享") +
                          " "
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { align: "center", label: "推荐时间", prop: "share_time" },
            }),
            _c("el-table-column", {
              attrs: {
                align: "center",
                label: "推荐人手机号",
                prop: "share_member_mobile",
              },
            }),
            _c("el-table-column", {
              attrs: {
                align: "center",
                label: "推荐人姓名",
                prop: "share_member_name",
              },
            }),
            _c("el-table-column", {
              attrs: { align: "center", label: "推荐结果", prop: "title" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(scope.row.status == 0 ? "未购买" : "已购买") +
                          " "
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { align: "center", label: "购买用户", prop: "" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(scope.row.purchase_username) +
                          "(" +
                          _vm._s(scope.row.purchase_mobile) +
                          ") "
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { align: "center", label: "订单号", prop: "order_no" },
            }),
            _c("el-table-column", {
              attrs: {
                align: "center",
                label: "城市仓",
                prop: "logistics_name",
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "fy" },
      [
        _c("el-pagination", {
          attrs: {
            type: "primary",
            background: "",
            "current-page": _vm.formData.page,
            "page-sizes": [10, 20, 50, 100],
            "page-size": _vm.formData.pageSize,
            layout: "total, sizes, prev, pager, next, jumper",
            total: _vm.total,
          },
          on: {
            "size-change": _vm.onHandleSizeChange,
            "current-change": _vm.onHandleCurrentChange,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }